export const colorPanelConfig = [
  {
    name: "pink",
    color: "#FF69B4",
  },
  {
    name: "red",
    color: "#ff0000",
  },
  {
    name: "yellow",
    color: "#ffff00",
  },
  {
    name: "green",
    color: "#00ff00",
  },
  {
    name: "blue",
    color: "#0000ff",
  },
];
